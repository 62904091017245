<template>
  <div>
    <a-card :title="$t('入库通知单')">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" :placeholder="$t('仓库')" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <client-select v-model="searchForm.client" :placeholder="$t('货主')" @change="search"></client-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <CounterpartySelect
            v-model="searchForm.counterparty"
            :placeholder="$t('往来单位')"
            @change="search"
          ></CounterpartySelect>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.is_completed" :placeholder="$t('状态')" allowClear style="width: 100%" @change="search">
            <a-select-option key="0" :value="false">{{ $t("进行中") }}</a-select-option>
            <a-select-option key="1" :value="true">{{ $t("已完成") }}</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.search"
            :placeholder="$t('单据编号, 往来单位单号, 备注')"
            allowClear
            @pressEnter="search"
          />
        </a-col>
        <a-col :span="24" :md="8" :xl="4" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">{{ $t("查询") }}</a-button>
        </a-col>

        <a-col :span="24" :md="12" :xl="12" style="margin-bottom: 12px; width: 400px">
          <a-button-group>
            <a-button icon="file-excel" @click="downloadTemplate">{{ $t("模板下载") }}</a-button>
            <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
              <a-button icon="upload">{{ $t("导入") }}</a-button>
            </a-upload>
            <a-button icon="download" @click="exportExcel">{{ $t("导出") }}</a-button>
            <!-- <a-button icon="download" @click="exportSummaryExcel">{{ $t("汇总导出") }}</a-button> -->
            <a-popconfirm :title="$t('确定删除吗?')" @confirm="bulkDelete">
              <a-button type="danger" icon="delete">{{ $t("批量删除") }}</a-button>
            </a-popconfirm>
          </a-button-group>
        </a-col>

        <a-col :span="24" :md="12" :xl="12" style="margin-bottom: 12px; width: 550px">
          <a-button-group>
            <a-button @click="printReceiptOrder">{{ $t("打印入库单") }}</a-button>
            <a-button @click="printShelveOrder">{{ $t("打印上架单") }}</a-button>
            <a-button @click="printStockInLabel">{{ $t("打印批次标签") }}</a-button>
            <a-button @click="openPrintPalletModal">{{ $t("打印容器标签") }}</a-button>
            <a-button @click="printPackingOrder">{{ $t("打印装箱单") }}</a-button>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="addReceiptOrder">
            {{ $t("新增入库通知单") }}
          </a-button>
        </div>
      </a-row>

      <a-row>
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :data-source="items"
          :pagination="pagination"
          :loading="loading"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :scroll="{ x: 1900 }"
          @change="tableChange"
        >
          <template slot="number" slot-scope="value, item, index">
            <a @click="detail(item)">{{ value }}</a>
          </template>
          <div slot="action" slot-scope="value, item, index">
            <a-button-group size="small">
              <a-button @click="detail(item)">{{ $t("详情") }}</a-button>
              <a-popconfirm :title="$t('确定强制完成吗?')" @confirm="complete(item)">
                <a-button type="primary">{{ $t("强制完成") }}</a-button>
              </a-popconfirm>
              <a-popconfirm :title="$t('确定作废重建吗?')" @confirm="voidAndRecreate(item)">
                <a-button type="danger">{{ $t("作废重建") }}</a-button>
              </a-popconfirm>
              <a-popconfirm :title="$t('确定作废吗?')" @confirm="voidItem(item)">
                <a-button type="danger">{{ $t("作废") }}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />{{ $t("正在导入中, 请等待...") }}</div>
    </a-modal>

    <a-modal v-model="printPalletModalVisible" :title="$t('打印容器标签')" @ok="printPalletNumber()">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-model-item :label="$t('容器编号')">
          <a-input v-model="palletNumber" allowClear />
        </a-form-model-item>
        <a-form-model-item :label="$t('打印方式')">
          <a-radio-group v-model="printModel" button-style="solid" style="width: 100%; text-align: center">
            <a-radio-button value="continuous" style="width: 50%">{{ $t("连续打印") }}</a-radio-button>
            <a-radio-button value="repeat" style="width: 50%">{{ $t("重复打印") }}</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item :label="$t('打印张数')">
          <a-input-number v-model="printQuantity" allowClear style="width: 100%" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { receiptOrderTemplate, receiptOrderImport } from "@/api/import";
import {
  receiptOrderList,
  receiptOrderVoid,
  receiptOrderBulkDelete,
  receiptOrderPrint,
  shelveOrderPrint,
  receiptOrderComplete,
  printReceiptPackingOrder,
} from "@/api/stockIn";
import { receiptOrdersExport, receiptOrderSummaryExport } from "@/api/export";
import { warehouseOption } from "@/api/option";
import { exportExcel } from "@/utils/excel";
import { columns } from "./columns.js";
import moment from "moment";
import { getLodop } from "@/utils/LodopFuncs";
import NP from "number-precision";
import JsBarcode from "jsbarcode";
import { palletCheckNumber } from "@/api/material";
import jrQrcode from "jr-qrcode";
// import lodopPrintReceiptOrder from "./printReceiptOrder";
import lodopPrintReceiptOrder from "@/views/printConfigs/printStockInOrder";
import lodopPrintShelveOrder from "@/views/printConfigs/printShelveOrder";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    CounterpartySelect: () => import("@/components/CounterpartySelect/"),
  },
  data() {
    return {
      columns,
      searchForm: { search: "", page: 1, ordering: undefined },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      warehouseItems: [],

      visible: false,
      targetItem: {},
      form: {},

      selectedRowKeys: [],
      selectionRows: [],
      importLoading: false,

      printPalletModalVisible: false,
      palletNumber: "",
      printModel: "continuous",
      printQuantity: 1,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
    palletLabelLength() {
      return this.$store.state.user.barConfig.palletLabelLength;
    },
    palletLabelWidth() {
      return this.$store.state.user.barConfig.palletLabelWidth;
    },
    stockInLabelLength() {
      return this.$store.state.user.barConfig.stockInLabelLength;
    },
    stockInLabelWidth() {
      return this.$store.state.user.barConfig.stockInLabelWidth;
    },
    stockInPrintConfig() {
      return this.$store.state.user.stockInOrderPrintConfig;
    },
    shelvePrintConfig() {
      return this.$store.state.user.shelveOrderPrintConfig;
    },
  },
  methods: {
    initialize() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
      });
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      receiptOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    addReceiptOrder() {
      this.$router.push({ path: "/stock_in/receipt_order_create" });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    detail(item) {
      this.$router.push({ path: "/stock_in/receipt_order_detail", query: { id: item.id } });
    },
    voidItem(item) {
      receiptOrderVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.list();
      });
    },
    voidAndRecreate(item) {
      receiptOrderVoid({ id: item.id }).then(() => {
        this.$message.success(this.$t("作废成功"));
        this.$router.push({ path: "/stock_in/receipt_order_create", query: { id: item.id } });
      });
    },
    complete(item) {
      receiptOrderComplete({ id: item.id }).then(() => {
        this.$message.success(this.$t("强制完成成功"));
        this.list();
      });
    },
    exportSummaryExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要导出的入库通知单!"));
        return false;
      }
      receiptOrderSummaryExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, this.$t("入库通知单汇总"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要导出的入库通知单!"));
        return false;
      }
      receiptOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, this.$t("入库通知单列表"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      receiptOrderTemplate()
        .then((resp) => {
          exportExcel(resp, this.$t("入库通知单导入模板"));
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        receiptOrderImport(data)
          .then(() => {
            this.$message.success(this.$t("导入成功"));
            this.list();
          })
          .catch((err) => {
            alert(this.$t("导入错误:") + "\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    bulkDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要删除的入库通知单!"));
        return false;
      }

      receiptOrderBulkDelete({ ids: this.selectedRowKeys }).then(() => {
        this.$message.success(this.$t("删除成功"));
        this.list();
      });
    },
    printText(value) {
      return value != null || value != undefined ? value : "";
    },
    printReceiptOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的入库通知单"));
        return false;
      }

      receiptOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        lodopPrintReceiptOrder(data, this.stockInPrintConfig);

        //         const LODOP = getLodop();
        //         LODOP.PRINT_INIT("");
        //         LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        //         for (let receiptOrderItem of data) {
        //           let canvas = document.createElement("canvas");
        //           JsBarcode(canvas, receiptOrderItem.number, {
        //             format: "CODE128",
        //             width: 2,
        //             height: 35,
        //             displayValue: true,
        //             background: "#ffffff",
        //             lineColor: "#000000",
        //           });
        //           let barcodeBase64 = canvas.toDataURL();

        //           let totalQuantity = 0;
        //           for (let item of receiptOrderItem.receipt_material_items) {
        //             totalQuantity = NP.plus(totalQuantity, item.total_quantity);
        //           }

        //           let pages = Math.ceil(receiptOrderItem.receipt_material_items.length / 14);
        //           for (let index = 1; index <= pages; index++) {
        //             let items = receiptOrderItem.receipt_material_items.slice((index - 1) * 14, index * 14);
        //             let tableRows = items.map((item) => {
        //               return `
        // <tr>
        //   <td>${this.printText(item.material_item.number)}</td>
        //   <td>${this.printText(item.material_item.name)}</td>
        //   <td>${this.printText(item.material_item.barcode)}</td>
        //   <td>${this.printText(item.total_quantity)}</td>
        //   <td>${this.printText(item.batch_number)}</td>
        //   <td></td>
        // </tr>`;
        //             });

        //             let pageContent = `
        // <div style="padding: 8px">
        //   <div style="font-size: 36px; text-align: center">
        //     <span>${this.$t("入库通知单")}</span>
        //     <span style="float: right;">${pages > 1 ? index : ""}</span>
        //   </div>

        //   <div style="margin-top: 8px; text-align: right">
        //     <img src="${barcodeBase64}" />
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 33%">${this.$t("货主")}: ${this.printText(receiptOrderItem.client_item.name)}</div>
        //     <div style="width: 34%">${this.$t("仓库")}: ${this.printText(receiptOrderItem.warehouse_item.name)}</div>
        //     <div style="width: 33%">${this.$t("往来单位单号")}: ${this.printText(receiptOrderItem.external_number)}</div>
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 33%">${this.$t("创建人")}: ${this.printText(receiptOrderItem.creator_item.name)}</div>
        //     <div style="width: 34%">${this.$t("创建时间")}: ${this.printText(receiptOrderItem.create_time)}</div>
        //     <div style="width: 33%">${this.$t("备注")}: ${this.printText(receiptOrderItem.remark)}</div>
        //   </div>

        //   <div style="margin-top: 16px; font-size: 14px">
        //     <table border="1" cellspacing="0" style="width: 100%; text-align: center">
        //       <tr>
        //         <th>${this.$t("产品编号")}</th>
        //         <th>${this.$t("产品名称")}</th>
        //         <th>${this.$t("产品条码")}</th>
        //         <th>${this.$t("应收数量")}</th>
        //         <th>${this.$t("批次编号")}</th>
        //         <th>${this.$t("推荐库位")}</th>
        //       </tr>
        //       ${tableRows.join("")}
        //       <tr>
        //         <td>${this.$t("合计")}:</td>
        //         <td></td>
        //         <td></td>
        //         <td>${this.printText(totalQuantity)}</td>
        //         <td></td>
        //         <td></td>
        //       </tr>
        //     </table>
        //   </div>

        //   <div style="margin-top: 24px; font-size: 14px">
        //     ${this.$t("收货人")}:
        //   </div>
        //   <div style="margin-top: 24px; font-size: 14px">
        //     ${this.$t("收货日期")}:
        //   </div>
        // </div>`;

        //             LODOP.NewPage();
        //             LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
        //           }
        //         }

        //         LODOP.PREVIEW();
      });
    },
    printShelveOrder() {
      const shelveOrderIds = [];
      for (const item of this.items) {
        if (this.selectedRowKeys.indexOf(item.id) !== -1) {
          shelveOrderIds.push(item.shelve_order);
        }
      }

      if (!shelveOrderIds.length) {
        this.$message.warning(this.$t("请选择要打印的上架单"));
        return false;
      }

      shelveOrderPrint({ ids: shelveOrderIds }).then((data) => {
        lodopPrintShelveOrder(data, this.shelvePrintConfig);

        //         const LODOP = getLodop();
        //         LODOP.PRINT_INIT("");
        //         LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        //         for (let shelveOrderItem of data) {
        //           let canvas = document.createElement("canvas");
        //           JsBarcode(canvas, shelveOrderItem.number, {
        //             format: "CODE128",
        //             width: 2,
        //             height: 35,
        //             displayValue: true,
        //             background: "#ffffff",
        //             lineColor: "#000000",
        //           });
        //           let barcodeBase64 = canvas.toDataURL();

        //           let totalQuantity = 0;
        //           for (let item of shelveOrderItem.shelve_material_items) {
        //             totalQuantity = NP.plus(totalQuantity, item.total_quantity);
        //           }

        //           let pages = Math.ceil(shelveOrderItem.shelve_material_items.length / 14);
        //           for (let index = 1; index <= pages; index++) {
        //             let items = shelveOrderItem.shelve_material_items.slice((index - 1) * 14, index * 14);
        //             let tableRows = items.map((item) => {
        //               return `
        // <tr>
        //   <td>${this.printText(item.material_item.number)}</td>
        //   <td>${this.printText(item.material_item.name)}</td>
        //   <td>${this.printText(item.material_item.barcode)}</td>
        //   <td>${this.printText(item.total_quantity)}</td>
        //   <td>${this.printText(item.batch_item.number)}</td>
        //   <td>${item.recommend_areas.join(", ")}</td>
        // </tr>`;
        //             });

        //             let pageContent = `
        // <div style="padding: 8px">
        //   <div style="font-size: 36px; text-align: center">
        //     <span>${this.$t("上架单")}</span>
        //     <span style="float: right;">${pages > 1 ? index : ""}</span>
        //   </div>

        //   <div style="margin-top: 8px; text-align: right">
        //     <img src="${barcodeBase64}" />
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 50%">${this.$t("货主")}: ${this.printText(shelveOrderItem.client_item.name)}</div>
        //     <div style="width: 50%">${this.$t("仓库")}: ${this.printText(shelveOrderItem.warehouse_item.name)}</div>
        //   </div>

        //   <div style="margin-top: 8px; display: flex">
        //     <div style="width: 50%">${this.$t("创建人")}: ${this.printText(shelveOrderItem.creator_item.name)}</div>
        //     <div style="width: 50%">${this.$t("创建时间")}: ${this.printText(shelveOrderItem.create_time)}</div>
        //   </div>

        //   <div style="margin-top: 16px; font-size: 14px">
        //     <table border="1" cellspacing="0" style="width: 100%; text-align: center">
        //       <tr>
        //         <th>${this.$t("产品编号")}</th>
        //         <th>${this.$t("产品名称")}</th>
        //         <th>${this.$t("产品条码")}</th>
        //         <th>${this.$t("上架数量")}</th>
        //         <th>${this.$t("批次编号")}</th>
        //         <th>${this.$t("推荐库区")}</th>
        //       </tr>
        //       ${tableRows.join("")}
        //       <tr>
        //         <td>${this.$t("合计")}:</td>
        //         <td></td>
        //         <td></td>
        //         <td>${this.printText(totalQuantity)}</td>
        //         <td></td>
        //         <td></td>
        //       </tr>
        //     </table>
        //   </div>
        // </div>`;

        //             LODOP.NewPage();
        //             LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
        //           }
        //         }

        //         LODOP.PREVIEW();
      });
    },
    openPrintPalletModal() {
      this.printPalletModalVisible = true;
    },
    async printPalletNumber() {
      if (!this.palletNumber || this.palletNumber.length === 0) {
        this.$message.warning(this.$t("未输入容器编号"));
        return;
      }

      if (!this.printQuantity || this.printQuantity <= 0) {
        this.$message.warning(this.$t("未输入打印张数"));
        return;
      }

      if (!this.palletLabelLength || !this.palletLabelWidth) {
        this.$message.warning(this.$t("请先在标签配置中设置标签参数!"));
        return;
      }

      const LODOP = getLodop();
      LODOP.PRINT_INIT("");
      LODOP.SET_PRINT_PAGESIZE(1, `${this.palletLabelLength}mm`, `${this.palletLabelWidth}mm`);
      LODOP.SET_PRINT_STYLEA(0, "FontSize", 20);

      const numberList = [];
      for (let index = 1; index <= this.printQuantity; index++) {
        if (this.printModel === "continuous") {
          numberList.push(`${this.palletNumber}${String(index).padStart(3, "0")}`);
        } else {
          numberList.push(this.palletNumber);
        }
      }

      if (this.printModel === "continuous") {
        await palletCheckNumber({ number_list: numberList });
      }

      for (let number of numberList) {
        LODOP.NewPage();
        LODOP.ADD_PRINT_BARCODE(
          `${this.palletLabelWidth * 0.15}mm`,
          `${this.palletLabelLength * 0.15}mm`,
          `${this.palletLabelLength * 0.7}mm`,
          `${this.palletLabelWidth * 0.7}mm`,
          "128A",
          number
        );
      }
      LODOP.PREVIEW();
    },
    printPackingOrder() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的入库通知单"));
        return false;
      }

      printReceiptPackingOrder({ ids: this.selectedRowKeys }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("");
        LODOP.SET_PRINT_PAGESIZE(1, "210mm", "297mm");

        const receiptOrderItems = [];
        for (const item of data) {
          item.receipt_record_material_items = item.receipt_record_material_items.filter((item) => item.pallet);
          if (item.receipt_record_material_items.length > 0) {
            receiptOrderItems.push(item);
          }
        }

        if (receiptOrderItems.length === 0) {
          this.$message.warning(this.$t("仅支持容器收货后打印装箱单"));
          return;
        }

        for (const receiptOrderItem of receiptOrderItems) {
          let totalQuantity = 0;
          for (let item of receiptOrderItem.receipt_record_material_items) {
            totalQuantity = NP.plus(totalQuantity, item.receipt_quantity);
          }

          let pages = Math.ceil(receiptOrderItem.receipt_record_material_items.length / 14);
          for (let index = 1; index <= pages; index++) {
            let items = receiptOrderItem.receipt_record_material_items.slice((index - 1) * 14, index * 14);
            let tableRows = items.map((item) => {
              return `
<tr>
  <td>${this.printText(item.pallet_number)}</td>
  <td>${this.printText(item.material_number)}</td>
  <td>${this.printText(item.material_name)}</td>
  <td>${this.printText(item.material_barcode)}</td>
  <td>${this.printText(item.material_spec)}</td>
  <td>${this.printText(item.receipt_quantity)}</td>
</tr>`;
            });

            let pageContent = `
<div style="padding: 8px">
  <div style="font-size: 36px; text-align: center">
    <span>${this.$t("装 箱 单")}</span>
    <span style="float: right;">${pages > 1 ? index : ""}</span>
  </div>

  <div style="font-size: 36px; margin-top: 8px; text-align: center">
    <span>PACKING LIST</span>
  </div>

  <div style="margin-top: 16px; display: flex">
    <div style="width: 50%">${this.$t("单号")}: ${this.printText(receiptOrderItem.number)}</div>
    <div style="width: 50%">${this.$t("货主")}: ${this.printText(receiptOrderItem.client_item.name)}</div>
  </div>

  <div style="margin-top: 16px; font-size: 14px">
    <table border="1" cellspacing="0" style="width: 100%; text-align: center">
      <tr>
        <th>${this.$t("容器编号")} <br /> Pallet Number</th>
        <th>${this.$t("产品编号")} <br /> Product Number</th>
        <th>${this.$t("产品名称")} <br /> Product Name</th>
        <th>${this.$t("产品条码")} <br /> Product Barcode</th>
        <th>${this.$t("箱规")} <br /> Spec</th>
        <th>${this.$t("数量")} <br /> Qauntity</th>
      </tr>
      ${tableRows.join("")}
      <tr>
        <td>${this.$t("合计")}: <br /> Total:</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>${this.printText(totalQuantity)}</td>
      </tr>
    </table>
  </div>
</div>`;
            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, "100%", "100%", pageContent);
          }
        }

        LODOP.PREVIEW();
      });
    },
    printStockInLabel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning(this.$t("请选择要打印的入库通知单"));
        return false;
      }

      if (!this.stockInLabelWidth || !this.stockInLabelLength) {
        this.$message.warning("请先在标签配置中设置标签参数!");
        return false;
      }

      receiptOrderPrint({ ids: this.selectedRowKeys }).then((data) => {
        const LODOP = getLodop();
        LODOP.PRINT_INIT("");

        for (const receiptOrderItem of data) {
          for (const receiptMaterialItem of receiptOrderItem.receipt_material_items) {
            const qrBase64 = jrQrcode.getQrBase64(
              JSON.stringify({
                material_item: {
                  number: receiptMaterialItem.material_item.number,
                  barcode: receiptMaterialItem.material_item.barcode,
                },
                batch_number: receiptMaterialItem.batch_number,
              }),
              { width: 160, height: 160 }
            );
            const htmlText = `
<html>
  <body style="margin: 0">
    <div style="width: ${this.stockInLabelLength}mm; height: ${this.stockInLabelWidth}mm">
      <table border="1" cellspacing="0" style="font-size: 20px; width: 100%; height: 100%; text-align: center">
        <tr style="height: 40%">
          <td colspan="4">
            <img src="${qrBase64}" />
          </td>
        </tr>
        <tr>
          <td>入库单号</td>
          <td colspan="3">${receiptOrderItem.number ?? ""}</td>
        </tr>
        <tr>
          <td>产品编号</td>
          <td colspan="3">${receiptMaterialItem.material_item.number ?? ""}</td>
        </tr>
        <tr>
          <td>产品名称</td>
          <td colspan="3">${receiptMaterialItem.material_item.name ?? ""}</td>
        </tr>
        <tr>
          <td>批次编号</td>
          <td colspan="3">${receiptMaterialItem.batch_number ?? ""}</td>
        </tr>
      </table>
    </div>
  </body>
</html>      
`;

            LODOP.NewPage();
            LODOP.ADD_PRINT_HTM(0, 0, `${this.stockInLabelWidth}mm`, `${this.stockInLabelLength}mm`, htmlText);
          }
        }

        LODOP.PREVIEW();
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
